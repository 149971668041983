import React, { FC } from "react"
import { graphql } from "gatsby"
import { setTheme } from "helpers/Theme"
import { Query } from "typings/graphql"
import { App } from "components/App/App"
import styled from "styled-components"
import { mq } from "styles"
import { Button } from "components/UI/Button/Button"

const Container = styled.div`
  min-height: 100vh;
  padding: 32px 30px 64px 30px;

  ${mq.minWidth("sm")} {
    padding: 48px 48px 80px 48px;
  }

  ${mq.minWidth("lg")} {
    padding: 48px 60px 96px 60px;
  }

  background: ${({ theme }) => theme.dark.canvas?.primary?.opacityHex};
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
`

const ButtonContainer = styled.div`
  display: flex;

  gap: 20px;
  margin-top: 40px;
`

type PageProps = {
  data: Query
}

const AccountDeletedPage: FC<PageProps> = ({ data }) => {
  const theme = setTheme(
    data.sanityTypography,
    data.sanityDarkTheme,
    data.sanityLightTheme,
    data.sanityGlobalTheme
  )

  return (
    <App theme={theme} layout="page" mode={"dark"}>
      <Container>
        <h1>Your Account Was Successfully Deleted</h1>
        <ButtonContainer>
          <Button to="/">Home</Button>
          <Button to="/shop">Shop All Products</Button>
        </ButtonContainer>
      </Container>
    </App>
  )
}

export const pageQuery = graphql`
  query AccountDeletedPage {
    sanityTypography {
      ...Typography
    }
    sanityDarkTheme {
      ...DarkTheme
    }
    sanityLightTheme {
      ...LightTheme
    }
    sanityGlobalTheme {
      ...GlobalTheme
    }
  }
`

export default AccountDeletedPage
